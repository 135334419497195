.resumewindow.row {
    margin: 0px;
}

.resumecard {
    min-height: 82vh;
    display: flex;
    justify-content: flex-start;
    align-items: flex-start;
}

.resumecard h2 {
    font-weight: bold;
    text-transform: capitalize;
    font-size: 2rem;
}

.resumecard h4 {
    font-size: 1.2rem;
    color: grey;
}

.resume-details {
    margin-top: -3rem;
    margin-left: 2rem;
    margin-right: 2rem;
    z-index: 2;
}

.imageicons{
    position: relative;
}

.linkedin-icon {
    position: absolute;
    right: 0;
    margin-right: 2rem;
    margin-top: -26px;
    cursor: pointer;
    z-index: 10;
}

.call-icon {
    position: absolute;
    right: 0;
    margin-right: 5rem;
    margin-top: -26px;
    cursor: pointer;
    z-index: 10;
}