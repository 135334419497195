.login-container {
    height: 90vh;
    display: flex;
    justify-content: center;
    align-items: center;
    background-image: linear-gradient(to left bottom, #d327d3, #ff0093, #ff6252, #ffaa05, #ebe612);
}

.login-form {
    width: 25vw;
    background-color: white;
    padding: 20px;
    border-radius: 10px;
    box-shadow: rgba(255, 255, 255, 0.1) 0px 1px 1px 0px inset, rgba(50, 50, 93, 0.25) 0px 50px 100px -20px, rgba(0, 0, 0, 0.3) 0px 30px 60px -30px;
}

.btn-block  {
    width: 100%;
    margin-top: 10px;
}

@media (max-width: 568px) {
    .login-form {
        width: 100%;
        margin: 10px;
    }
}