.navigation {
  height: 10vh;
  background-color: black;
  color: white;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0 20px;
}

a {
  color: inherit;
  text-decoration: none;
}

.navigations {
  display: flex;
  gap: 1rem;
}

.navigations h5 {
  font-size: 15px;
  cursor: pointer;
  padding: 10px;
}

.navigation h5:hover {
  border-bottom: 1px solid white;
  transition: 3sec;
}

.hamburger:hover {
  cursor: pointer;
  background-color: rgb(32, 90, 90);
}

.hamburger {
  padding: 10px;
}

.proname {
  position: relative;
}

.visit {
  position: absolute;
  top: 0;
  background-color: black;
  color: white;
  margin-top: 10vh;
  z-index: 20;
}

.visit h5:hover {
  border: 0px;
}

.cpointer {
  cursor: pointer;
}

.form-control {
  border-radius: 0px !important;
}

.btn {
  border-radius: 0px !important;
}