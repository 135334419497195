.details p {
    margin-left: 1rem;
}

.details h5 {
    font-weight: bold;
    font-size: 1rem;
    color: grey;
}

.details h5 span {
    color: rgb(95, 173, 246);
    text-transform: uppercase;
    font-size: 1.2rem;
}

.login-form-position h3 {
    margin-bottom: 1rem;
}

.login-form-position h3 span {
    font-size: 13px;
    color: crimson;
}

span.caps {
    text-transform: capitalize;
}

.search input {
    border: 0px;
}

.search {
    border: 2px solid #1a8754;
}

.sort {
    border: 2px solid #1a826f;
    /* display: flex;
    align-items: center;
    justify-content: space-between;
    padding-right: 10px; */
    position: relative;
}

.sort select {
    border: 0px;
}

.sort .downicon {
    position: absolute;
    top: 0px;
    right: 0px;
    margin-top: 5px;
    margin-right: 5px;
    color: #1a826f;
}